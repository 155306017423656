// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: clip;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel,
.privacy-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.return-to-top-sentinel {
    position: absolute;
    top: 400px;
    left: 0;
}

@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        transition: all var(--animSpeed) ease-in-out;
        transition-property: transform, box-shadow;
        z-index: 1000;

        &.stuck {
            .shadow();
            transform: translateY(var(--headerScrollTransform));

            .logo {
                transform: translateY(28px) scale(.76);
                width: 304px;

                img {
                    max-width: 105px;
                }

                span {
                    display: none;
                }
            }

            #search-engine,
            .header-tools-wrapper {
                transform: translateY(21px);
            }

            .tree-top-hover,
            .mainmenu a {
                padding-block: calc(18px - 1/2 * var(--headerHeightChange));
            }
        }
    }

    body:has(.shop-header.stuck) {
        .shop-header + * {
            padding-top: var(--headerHeightChange);
        }
    }

    .logo,
    #search-engine,
    .header-tools-wrapper {
        transition: transform var(--animSpeed) ease-in-out;
    }
}

.top-bar {
    position: relative;
    line-height: 22px;
    padding: 10px 0 11px;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    gap: 50px;

    &:after {
        content: '';
        .center-lr();
        bottom: 0;
        width: 101vw;
        height: 1px;
        background-color: @border-color;
    }
}

.header-shipment {
    color: @header-color;
    margin-right: auto;

    &:before {
        content: '\e804';
        font-family: icomoon;
        float: left;
        color: @main-color;
        font-size: 18px;
        margin-right: 11px;
    }
}

.header-contact {
    display: flex;
    gap: 22px;

    a {
        display: block;
        color: @header-color;

        &:after {
            font-family: icomoon;
            float: left;
            margin-right: 11px;
            color: @main-color;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .phone:after {
        content: '\e80a';
        font-size: 19px;
    }

    .email:after {
        content: '\e805';
    }
}

.header-social-links {
    display: flex;
    gap: 4px;

    a {
        display: flex;
        color: @header-color;
        font-size: 22px;

        &:after {
            font-family: icomoon;
        }

        &:hover {
            color: @main-color;
        }
    }

    .facebook-link:after {
        content: '\e806';
    }

    .instagram-link:after {
        content: '\e807';
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    height: 106px;
    justify-content: space-between;
}

// LOGO
.logo {
    display: flex;
    transform-origin: left top;
    height: 48px;

    figure {
        height: 100%;
        aspect-ratio: ~"105/48";
    }

    img {
        .img-scale-down();
    }

    span {
        color: @header-color;
        font-size: 18px;
        font-weight: 300;
        display: flex;
        align-items: center;

        &:before {
            content: '';
            height: 100%;
            width: 1px;
            background-color: #E9E9E9;
            margin: 0 46px;
        }
    }

    b {
        font-weight: bold;
        text-transform: uppercase;
        margin-left: 6px;
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 31px;

    > * {
        position: relative;
        display: block;
        cursor: pointer;

        &:before {
            font-family: icomoon;
            position: absolute;
            color: @header-color;
            line-height: 1;
        }
    }

    .search-engine-trigger:before {
        content: '\e80d';
    }

    .log-button:before {
        content: '\e811';
    }

    #wishlist-box:before {
        content: '\e809';
    }

    #cart-box:before {
        content: '\e90e';
    }
}

@media (min-width: 1280px) {
    .header-tools-wrapper > * {

        &:before {
            font-size: 28px;
            left: 0;
            top: 3px;
        }

        span,
        b {
            display: block;
            color: @header-color;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
        }

        b {
            margin-top: -5px;
        }

        em {
            display: none;
        }

        &:hover {

            span,
            b {
                color: @font-color;
            }
        }
    }

    .log-button {
        padding-left: 33px;
    }

    #wishlist-box {
        padding-left: 43px;

        &:after {
            left: 23px;
            content: attr(data-wishlist-count);
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }
    }

    #cart-box {
        padding-left: 35px;

        &:before {
            font-size: 32px;
            line-height: 30px;
            height: 30px;
            top: 2px;
        }

        i {
            left: 14px;
        }
    }

    #wishlist-box:after,
    #cart-box i {
        position: absolute;
        top: 0;
        text-align: center;
        border-radius: 50%;
        font-style: normal;

        font-weight: bold;
        color: white;
        background-color: @main-color;
        width: 14px;
        line-height: 14px;
        font-size: 9px;
    }

    .tree-trigger,
    .tree-canvas,
    .search-engine-trigger {
        display: none;
    }
}

// SEARCH ENGINE
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 420px;
        z-index: 100;

        &.show,
        &.show-last-phrases {

            #suggestions {
                .setup-appear();
                .appear();
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 44px;
            padding: 0 64px 0 22px;
            background-color: @light-bg;
            border-radius: 22px;
            color: @header-color;
            font-size: 15px;
            font-family: @main-font-family;
            border: 1px solid @border-color;

            &::-webkit-input-placeholder {
                color: @font-color;
            }
            &::-moz-placeholder {
                color: @font-color;
            }
            &:-ms-input-placeholder {
                color: @font-color;
            }
            &:-moz-placeholder {
                color: @font-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 64px;
            height: 44px;
            color: @header-color;
            font-size: 22px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '\e80d';
                font-family: icomoon;
            }

            &:hover {
                color: @font-color;
            }
        }
    }
}

#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

// MAIN BAR
#main-bar {
    position: relative;
    display: flex;

    &:after {
        content: '';
        .center-lr();
        bottom: 0;
        width: 101vw;
        height: 100%;
        z-index: -1;
        background-color: @light-bg;
        border-top: 1px solid @border-color;
        border-bottom: 1px solid @border-color;
    }
}

.tree-top-hover,
.mainmenu a {
    padding-block: 18px;
}

// TREE TOP
#tree-top {
    position: relative;
    z-index: 100;

    &:hover {

        .tree-top-hover {

            &:after {
                transform: scaleY(-1);
            }
        }

        .tree-top-menu {
            .appear();
            transition-delay: 0.2s;
        }
    }
}

.tree-top-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @sec-color;
    color: white;
    height: 100%;
    transition: height 0.2s ease-in-out;
    width: 280px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    z-index: 1;
    position: relative;

    &:after {
        content: '\e800';
        font-family: icomoon;
        font-weight: normal;
        font-size: 7px;
        margin-left: 15px;
    }
}

.tree-top-hover-hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 16px;
    height: 12px;
    margin-right: 19px;

    i {
        border-radius: 1px;
        height: 2px;
        width: 100%;
        background-color: white;

        &:nth-child(3) {
            width: 10px;
        }
    }
}

.tree-top-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 280px;
    background-color: white;
    .shadow();
    padding: 5px 0;
    .setup-appear();

    li {
        position: relative;
        padding-top: 1px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 22px;
            width: ~"calc(100% - 44px)";
            height: 1px;
            background-color: @border-color;
        }
        
        &:first-child {
            padding-top: 0;

            &:before {
                display: none;
            }
        }

        &:hover {

            > a {
                color: @font-color;
            }

            > ul {
                .appear();
            }
        }
    }

    .parent > a {
        padding-right: 44px;
        position: relative;

        &:after {
            content: '\e800';
            font-family: icomoon;
            position: absolute;
            top: 0;
            right: 22px;
            width: 20px;
            height: 100%;
            font-weight: normal;
            font-size: 9px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transform: rotate(-90deg) translate(-3px, 5px);
        }
    }

    a {
        display: block;
        white-space: nowrap;
        color: @header-color;
        font-weight: 600;
        padding: 13px 22px;

        &:hover {
            color: #7B7B7B;
        }
    }

    ul {
        position: absolute;
        top: -5px;
        left: 100%;
        background-color: white;
        .shadow();
        padding: 5px 0;
        min-width: 280px;
        .setup-appear();
        transform: translateX(-10px);
    }
}

// MAINMENU
.mainmenu {
    display: flex;
    justify-content: space-around;
    padding: 0 29px;
    flex-grow: 1;

    li {

        &:nth-child(-n+3) a {
            font-weight: 600;
        }

        &.selected a {
            color: @sec-color;
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;
        color: @header-color;
        padding-inline: 10px;
        transition: height 0.2s ease-in-out;

        &:hover {
            color: #7B7B7B;
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGap);
    width: 100%;

    &:empty {
        display: none;
    }
}

.product {
    position: relative;
    border: 1px solid @border-color;
    padding: 19px 15px;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 15px;
    border-radius: var(--borderRadius);

    &.swiper-slide {

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform 0.15s ease-in-out;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }
}

a.product-name {
    font-weight: normal;
    display: block;
    color: @header-color;
    transition: opacity 0.15s ease-in-out;
    z-index: 1; // IMPORTANT

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 5px;
    z-index: 1;
}

.product-price {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.promo-price b {
        color: @error-color;
    }

    b, del {

        &:after {
            content: ' zł';
        }
    }

    b {
        font-size: 17px;
        font-weight: 600;
        color: @header-color;
    }

    del {
        font-size: 14px;
        color: #9A9A9A;
        margin-top: -4px;
    }
}

.product-add,
.product-wishlist {
    width: 34px;
    height: 34px;
    border-radius: var(--borderRadius);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:after {
        font-family: icomoon;
    }
}

.product-add {
    background-color: @main-color;
    color: white;
    font-size: 21px;

    &:after {
        content: '\e90e';
    }

    &.disabled {
        display: none;
    }

    &:hover {
        background-color: @sec-color;
    }
}

.product-wishlist {
    margin-left: auto;
    background-color: @light-bg;
    color: @font-color;
    font-size: 20px;

    &:after {
        content: '\e809';
    }

    &:hover {
        color: @error-color;
    }

    &.existing {
        background-color: @error-color;
        color: white;
    }
}

.product-badges {
    position: absolute;
    top: 19px;
    left: 15px;
    display: flex;
    align-items: start;
    gap: 1px;
    max-width: calc(100% - 30px);
    justify-content: space-between;

    > span {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: white;
        background-color: @header-color;
        padding: 0 10px;
    }

    .promo-badge {
        background-color: @error-color;
    }
}

.product-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: white;
    padding: 19px 15px;
    transition: opacity 0.15s ease-in-out;

    > span,
    > p,
    > em {
        transform: translateY(-8px);
        transition: transform .15s ease-in-out;
    }

    > span {
        font-weight: normal;
        color: @header-color;
        display: block;
    }

    > p {
        margin-top: 15px;
        color: @font-color;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(7 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > em {
        margin-top: 15px;
        display: block;
        color: @font-color;
        font-style: normal;

        strong {
            font-weight: 600;
            color: @header-color;
        }
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-name {
            opacity: 0;
            transition-delay: .15s;
        }

        .product-hover {
            opacity: 1;
            transition-delay: 0.15s;

            > strong,
            > p,
            > em {
                transition-delay: .15s;
                transform: translateY(0);
            }
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
            transition: opacity 0.15s ease-in-out;
            transition-delay: 0.15s;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity 0.15s ease-in-out forwards;
            animation-delay: 0.15s;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 20px 0;
    margin-bottom: ~"calc(var(--rowGap) * -1)";

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 10px;
        }

        &:last-child {
            font-weight: bold;

            &:after {
                display: none;
            }

            a,
            span {
                color: @main-color;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// COLORS CHOICE (FILTER & DETAIL)
:root {
    --colorsBackgroundWidth: 8px;
}

#filter .colors-choice,
.colors-choice {

    > span {
        display: none;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        position: relative;
        z-index: 1;
    }

    li {
        font-size: 0;
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin: var(--colorsBackgroundWidth);
        position: relative;
        border-radius: 2px;

        &:before {
            content: '';
            position: absolute;
            inset: calc(-1 * var(--colorsBackgroundWidth));
            background: white;
            border: 1px solid @border-color;
            border-radius: inherit;
            z-index: -1;
        }

        @media(min-width: 1280px) {
            &:hover:before {
                border-color: @header-color;
            }
        }

        &.selected,
        &:has(input:checked) {

            &:before {
                border: 2px solid @main-color;
            }
        }

        img {
            background: @border-color;
            .img-crop();
        }
    }

    label {
        font-size: 0 !important;
        padding: 0 !important;
        width: 100%;
        height: 100%;
        z-index: 3;

        &:before {
            position: absolute;
            width: calc(100% + 2 * var(--colorsBackgroundWidth));
            height: calc(100% + 2 * var(--colorsBackgroundWidth));
            top: calc(-1 * var(--colorsBackgroundWidth));
            left: calc(-1 * var(--colorsBackgroundWidth));
            background: transparent;
            border: none;
        }

        &:after {
            display: none !important;
        }
    }
}